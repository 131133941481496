import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { useIsMobile } from '@hooks/use-is-mobile';
import { useIsPaying } from '@hooks/use-is-paying';
import { WalletToken } from '@typings/wallet-asset.types';
import { cn } from '@utils/cn';
import { uppercaseFirstLetter } from '@utils/format-string.util';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useSwitchChain } from 'wagmi';

type Props = {
  nativeToken?: WalletToken;
  wrongEvmNetwork?: boolean;
  wrongCardanoNetwork?: boolean;
  extCardanoWalletName?: string;
  extEvmWalletName?: string;
  correctChainId?: number;
  enteredAmount: boolean;
  notEnoughNativeToken: boolean;
  notEnoughToken: boolean;
  invalidPlan: boolean;
  selectedWalletToken?: WalletToken;
  email: string | null;
};

const ErrorTypes = {
  NOT_ENOUGH_TOKEN: 'NOT_ENOUGH_TOKEN',
  NOT_ENOUGH_NATIVE_TOKEN: 'NOT_ENOUGH_NATIVE_TOKEN',
  WRONG_CARDANO_NETWORK: 'WRONG_CARDANO_NETWORK',
  PAYMENT_IN_PROGRESS: 'PAYMENT_IN_PROGRESS',
  WRONG_EVM_NETWORK: 'WRONG_EVM_NETWORK',
  CARDANO_NOT_SUPPORTED: 'CARDANO_NOT_SUPPORTED',
  INVALID_PLAN: 'INVALID_PLAN',
  INVALID_EMAIL: 'INVALID_EMAIL',
} as const;

type WrongCardanoNetworkType = { type: typeof ErrorTypes.WRONG_CARDANO_NETWORK; walletNameToUse: string };
type WrongEvmNetworkType = { type: typeof ErrorTypes.WRONG_EVM_NETWORK; walletNameToUse: string };
type NotEnoughNativeTokenType = { type: typeof ErrorTypes.NOT_ENOUGH_NATIVE_TOKEN; nftBridgeSelected: boolean };
type PaymentInProgressType = { type: typeof ErrorTypes.PAYMENT_IN_PROGRESS };
type CardanoNotSupportedType = { type: typeof ErrorTypes.CARDANO_NOT_SUPPORTED };
type InvalidPlanType = { type: typeof ErrorTypes.INVALID_PLAN };
type NotEnoughTokenType = { type: typeof ErrorTypes.NOT_ENOUGH_TOKEN };

type Error = { showingError: boolean } & (
  | PaymentInProgressType
  | CardanoNotSupportedType
  | NotEnoughNativeTokenType
  | WrongCardanoNetworkType
  | WrongEvmNetworkType
  | InvalidPlanType
  | NotEnoughTokenType
);

export const UnsupportedWalletMessage = memo(
  ({
    nativeToken,
    extCardanoWalletName,
    wrongCardanoNetwork,
    wrongEvmNetwork,
    extEvmWalletName,
    correctChainId,
    notEnoughToken,
    enteredAmount,
    invalidPlan,
    notEnoughNativeToken,
    selectedWalletToken,
    email,
  }: Props) => {
    const [canShow, setCanShow] = useState(false);
    const isPaying = useIsPaying();
    const [cardanoNotSupported, setCardanoNotSupported] = useState(false);
    const [hasError, setHasError] = useState<Error | null>(null);
    const { switchChain } = useSwitchChain();

    const wrongNetworkState = hasError?.showingError && canShow ? { opacity: 1 } : { opacity: 0 };

    useEffect(() => {
      if (email) {
        setTimeout(() => {
          setCanShow(true);
        }, 1000);
      }
    }, [email]);

    const error = useMemo(() => {
      if (wrongEvmNetwork && nativeToken && enteredAmount)
        return { type: ErrorTypes.WRONG_EVM_NETWORK, walletNameToUse: extEvmWalletName as string };
      if (cardanoNotSupported) return { type: ErrorTypes.CARDANO_NOT_SUPPORTED };
      if (notEnoughNativeToken) return { type: ErrorTypes.NOT_ENOUGH_NATIVE_TOKEN };
      if (notEnoughToken) return { type: ErrorTypes.NOT_ENOUGH_TOKEN };
      if (wrongCardanoNetwork)
        return {
          type: ErrorTypes.WRONG_CARDANO_NETWORK,
          walletNameToUse: uppercaseFirstLetter(extCardanoWalletName ?? ''),
        };
      if (isPaying) return { type: ErrorTypes.PAYMENT_IN_PROGRESS };
      if (invalidPlan) return { type: ErrorTypes.INVALID_PLAN };
      return null;
    }, [
      cardanoNotSupported,
      enteredAmount,
      isPaying,
      nativeToken,
      wrongCardanoNetwork,
      wrongEvmNetwork,
      extEvmWalletName,
      extCardanoWalletName,
      notEnoughToken,
      invalidPlan,
      notEnoughNativeToken,
    ]);

    const nativeWalletTokenRef = useRef(nativeToken);
    const selectedWalletTokenRef = useRef(selectedWalletToken);

    useEffect(() => {
      if (!hasError?.showingError) {
        nativeWalletTokenRef.current = nativeToken;
        selectedWalletTokenRef.current = selectedWalletToken;
      }
    }, [hasError, nativeToken, selectedWalletToken]);

    useEffect(() => {
      selectedWalletTokenRef.current = selectedWalletToken;
    }, [selectedWalletToken]);

    useEffect(() => {
      if (error) {
        setHasError({ ...error, showingError: true } as Error);
      } else
        setHasError((oldError) => {
          return oldError ? { ...oldError, showingError: false } : null;
        });
    }, [error]);

    const isMobile = useIsMobile();

    useEffect(() => {
      if (isMobile && nativeToken?.chainName === NetworkChainType.CARDANO) {
        setCardanoNotSupported(true);
      } else {
        setCardanoNotSupported(false);
      }
    }, [nativeToken, isMobile]);

    const wrongNetworkSpring = useSpring(wrongNetworkState);

    const getErrorMessage = () => {
      switch (hasError?.type) {
        case ErrorTypes.INVALID_PLAN:
          return (
            <span className="block px-10 text-center">
              Invalid payment plan. Please go back to the original website, refresh and try clicking the payment URL
              again.
            </span>
          );
        case ErrorTypes.WRONG_EVM_NETWORK:
        case ErrorTypes.WRONG_CARDANO_NETWORK:
          return (
            <>
              Please{' '}
              <span
                onClick={
                  hasError?.type === ErrorTypes.WRONG_CARDANO_NETWORK
                    ? undefined
                    : () => switchChain && switchChain({ chainId: correctChainId as number })
                }
                className={cn(
                  hasError?.type === ErrorTypes.WRONG_EVM_NETWORK &&
                    "relative inline-block cursor-pointer after:block after:h-[1px] after:w-full after:bg-meldred after:content-['_']",
                )}
              >
                switch to the correct network
              </span>{' '}
              in {hasError.walletNameToUse} to bridge.
            </>
          );
        case ErrorTypes.NOT_ENOUGH_TOKEN:
          return `Not enough ${selectedWalletToken?.symbol ?? selectedWalletTokenRef.current?.symbol ?? ''} to cover the payment cost.`;
        case ErrorTypes.NOT_ENOUGH_NATIVE_TOKEN:
          return `Not enough ${nativeToken?.symbol ?? nativeWalletTokenRef.current?.symbol ?? ''} to cover the transaction fee.`;
        case ErrorTypes.PAYMENT_IN_PROGRESS:
          return 'Payment can take a few minutes to be processed. Please wait.';
        case ErrorTypes.CARDANO_NOT_SUPPORTED:
          return 'Cardano is not supported on mobile yet.';

        default:
          return '';
      }
    };

    return (
      <animated.div
        style={wrongNetworkSpring}
        className={cn(
          'm-0 mx-auto mb-3 box-border flex h-[60px] w-full items-center justify-center rounded-lg bg-meldblack px-4 text-center opacity-0 ',
        )}
      >
        <p className={cn('font-semibold text-[12px] leading-[18px] text-meldred', isPaying && 'px-2 text-meldblue')}>
          {getErrorMessage()}
        </p>
      </animated.div>
    );
  },
);
