import { useEthersSigner } from './use-ethers-signer';
import { getPayments } from '@utils/get-payments';
import { ethers } from 'ethers';
import { useQuery } from '@tanstack/react-query';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { useUnsupportedExtNetwork } from './use-unsupported-ext-network';
import { GET_PAYMENTS } from 'src/contants/queries';

const CACHE_KEY = (address: string) => `payments_${address}`;

export const usePayments = () => {
  const wallet = useEthersSigner();
  const availableTokens = useStore((state) => state.availableTokens, shallow);
  const { evmAddress } = useStore((state) => state.evmData, shallow);

  const { wrongEvmNetwork } = useUnsupportedExtNetwork();

  const { isLoading, data, isError } = useQuery(
    [GET_PAYMENTS, evmAddress],
    () => {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const { data: cachedData, blockNumber: cachedBlockNumber } = JSON.parse(
            localStorage.getItem(CACHE_KEY(evmAddress as string)) ?? 'null',
          ) ?? { data: [], blockNumber: 0 };

          const { data, blockNumber } = await getPayments(
            wallet as ethers.providers.JsonRpcSigner,
            availableTokens as AvailableToken[],
            cachedBlockNumber || undefined,
          );

          const newCachedData = data.concat(cachedData);
          localStorage.setItem(
            CACHE_KEY(evmAddress as string),
            JSON.stringify({ data: newCachedData, blockNumber: blockNumber + 1 }),
          );
          resolve(newCachedData);
        }, 2000);
      });
    },
    {
      enabled: !!wallet && !!availableTokens && !wrongEvmNetwork,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isLoading,
    data,
    isError,
  };
};
