import { useEffect, useMemo } from 'react';
import { Selector } from './selector';
import { cn } from '../../utils/cn.ts';
import { useStore } from '@store/store.ts';
import { shallow } from 'zustand/shallow';
import { formatCryptoBalance } from '@utils/format-currency/format-currency.util.ts';
import { useCountUp } from 'use-count-up';
import { useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { SuccessMessage } from './success-message.tsx';
import { useNativeToken } from '@hooks/use-native-token.ts';
import { useCalculateTxFee } from '@hooks/use-calculate-tx-fee.ts';
import { Balance } from '@components/balance.tsx';
import { PaymentFee } from '@components/payment-fee.tsx';

export const TokenSelector = ({ decimalSeparator }: { decimalSeparator: string }) => {
  const { completed, notEnoughToken, amountInFiat, explorerUrl } = useStore((state) => state.data, shallow);
  const { evmRequiresApproval } = useStore((state) => state.evmData, shallow);

  const { amount, inputError } = useStore((state) => state.inputData, shallow);
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const transactionCost = useStore((state) => state.data.transactionCost);

  const tokenAmountRef = useRef(0);

  const nativeToken = useNativeToken({ network: selectedWalletToken?.network });
  const { isLoading: isLoadingTransactionFee } = useCalculateTxFee();

  const tokenAmount = useMemo(() => {
    const formattedAmount = selectedWalletToken?.amount ?? 0;
    if (formattedAmount === '0.00') return 0;

    return Number(formattedAmount);
  }, [selectedWalletToken]);

  const { value, reset } = useCountUp({
    isCounting: true,
    end: tokenAmountRef.current,
    duration: 1.3,
    easing: 'easeOutCubic',
    start: 0,
    formatter: (value) =>
      formatCryptoBalance(
        value,
        undefined,
        undefined,
        formatCryptoBalance(selectedWalletToken?.amount.toString() ?? '0').split(decimalSeparator)?.[1]?.length ?? 0,
      ),
  });

  useEffect(() => {
    setTimeout(() => {
      tokenAmountRef.current = tokenAmount;
      reset();
    }, 100);
  }, [tokenAmount, reset]);

  const selectorState = completed
    ? { from: { opacity: 1, y: 0 }, to: { opacity: 0, y: -20 } }
    : { from: { opacity: 0, y: -20 }, to: { opacity: 1, y: 0 } };

  const selectorSpring = useSpring({
    ...selectorState,
    delay: completed ? 200 : 500,
  });

  return (
    <>
      <SuccessMessage
        data={
          completed
            ? {
                explorerUrl: explorerUrl as string,
                amount: formatCryptoBalance(amount, selectedWalletToken?.symbol, true),
              }
            : undefined
        }
      />
      <div className={cn('w-full')}>
        <div
          className={cn(
            'relative flex w-full justify-center transition-all duration-500',
            completed && 'pointer-events-none',
          )}
        >
          <animated.div className={cn('w-full md:max-w-[471px]')} style={selectorSpring}>
            <div className={cn('transition-all duration-500')}>
              <Balance
                isLoading={false}
                animatedValue={value}
                formattedValue={formatCryptoBalance(tokenAmount)}
                tokenAmount={tokenAmount}
              />
            </div>
            <div className="my-[7px] flex w-full flex-col md:w-full ">
              <div className={cn('relative flex flex-col gap-[14px] transition-all duration-500 md:flex-row')}>
                <Selector />
                <div className="relative flex  h-[59px] grow items-center rounded-lg bg-white px-4 font-semibold text-xl text-black/60 outline-none">
                  {formatCryptoBalance(amount)}
                </div>
              </div>
              <div className="relative">
                <PaymentFee
                  inputError={inputError}
                  selectedWalletToken={selectedWalletToken}
                  nativeToken={nativeToken}
                  isLoadingTransactionFee={isLoadingTransactionFee}
                  amount={amount}
                  transactionCost={transactionCost}
                  notEnoughToken={notEnoughToken}
                  needsApproval={evmRequiresApproval ?? true}
                  amountInFiat={amountInFiat}
                />
              </div>
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
};
