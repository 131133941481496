import { calculateZerosAfterDecimalPoint, toFixedNoRounding } from './format-currency';

export const bankersRounding = (num: number) => {
  const numberOfZeros = calculateZerosAfterDecimalPoint(num);
  const decimalPlaces = numberOfZeros > 0 ? 2 : 0;
  const integerValueIsZero = num.toString().includes('.') ? Number(num.toString().split('.')[0]) === 0 : false;
  if (decimalPlaces === 2 || integerValueIsZero) return toFixedNoRounding(num, numberOfZeros + 2);
  const m = 10 ** decimalPlaces;
  const n = +(decimalPlaces ? num * m : num).toFixed(8);
  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8;
  const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
  return decimalPlaces ? r / m : r;
};

// @ts-expect-error 2339
window.meld = { testNumber: (value: number) => bankersRounding(value) };
