import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { MUI_THEME } from './theme';
import { Layout } from './components/layout';
import { TokenSelector } from './components/token-selector';
import { cn } from './utils/cn';
import { ExtCardanoWallets } from './components/with-cardano-external-wallets';
import { useInitialBEData } from '@hooks/use-initial-be-data/use-initial-be-data';
import { useWalletsRegisterAndSynced } from '@hooks/use-wallets-register-and-synced';
import { useWalletTokens } from '@hooks/use-wallet-tokens';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';
import { UnsupportedWalletMessage } from '@components/unsupported-wallet-message';
import { useOverrideWcColors } from '@hooks/use-override-wc-colors';
import { ConfirmButton } from '@components/confirm-button';
import { BillDetails } from '@components/bill-details';
import { useEffect, useRef, useState } from 'react';
import { getDecimalSeparator } from '@utils/format-currency/get-decimals-separator';
import getUserLocale from 'get-user-locale';
import { useNativeToken } from '@hooks/use-native-token';
import { useEnoughToken } from '@hooks/use-enough-token';
import { usePayments } from '@hooks/use-payments';

function App() {
  const { amount } = useStore((state) => state.inputData, shallow);
  const { evmWalletName } = useStore((state) => state.evmData, shallow);
  const { cardanoWalletName } = useStore((state) => state.cardanoData, shallow);
  const { notEnoughToken, invalidPlan, email } = useStore((state) => state.data, shallow);
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);

  const { wrongEvmNetwork, wrongCardanoNetwork, correctChainId } = useUnsupportedExtNetwork();
  const detailsComponentRef = useRef<HTMLDivElement>(null);
  const [offsetOfDetailsComponent, setoffsetOfDetailsComponent] = useState(0);
  const { thousandsSeparator, decimalSeparator } = useStore((state) => state.numberFormatting, shallow);
  const setNumberFormatting = useStore((state) => state.setNumberFormatting);
  const nativeToken = useNativeToken({ network: selectedWalletToken?.network });

  const enoughToken = useEnoughToken();

  useEffect(() => {
    const decimalSeparator = getDecimalSeparator(getUserLocale());
    setNumberFormatting({ decimalSeparator, thousandsSeparator: decimalSeparator === '.' ? ',' : '.' });
  }, [setNumberFormatting]);

  useOverrideWcColors();
  useInitialBEData();
  useWalletsRegisterAndSynced();
  useWalletTokens();
  usePayments();

  useEffect(() => {
    if (detailsComponentRef.current) {
      const { y, height } = detailsComponentRef.current.getBoundingClientRect();
      setoffsetOfDetailsComponent(y + height);
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MUI_THEME}>
        <ExtCardanoWallets />
        <Layout>
          <TokenSelector key={thousandsSeparator} decimalSeparator={decimalSeparator} />
          <div className={cn('w-full md:max-w-[451px]')}>
            <div className="mt-[90px]">
              <UnsupportedWalletMessage
                enteredAmount={+amount > 0}
                correctChainId={correctChainId}
                extCardanoWalletName={cardanoWalletName ?? undefined}
                extEvmWalletName={evmWalletName}
                nativeToken={nativeToken}
                wrongEvmNetwork={wrongEvmNetwork}
                wrongCardanoNetwork={wrongCardanoNetwork}
                notEnoughNativeToken={notEnoughToken}
                notEnoughToken={!enoughToken}
                invalidPlan={invalidPlan}
                selectedWalletToken={selectedWalletToken}
                email={email}
              />
              <ConfirmButton />
            </div>
          </div>
          <BillDetails offsetOfDetailsComponent={offsetOfDetailsComponent} />
        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
