import { useStore } from '@store/store';
import evmWalletUtils from '@utils/evm/evmWalletUtils';
import { BNDecimals } from '@utils/format-currency/big-number';

import { WalletTokensResponse } from './wallet-tokens.types';
import { axiosInstance } from '@api/axios';
import { formatEther } from 'ethers/lib/utils';
import { SupportedWallet } from '@typings/wallet';

/**
 * Here we fetch the native token balances of the external EVM address because once an external EVM wallet
 * connects to our app, we register it with the BE but the BE doesn't have any native EVM token balances
 * until the very next block. This prevents us from showing missing balances, fetching the native EVM token
 * balance from chain directly.
 *
 * @param walletAddress string
 */
export const getMetamaskWalletTokensQuery = async (walletAddress: string): Promise<WalletTokensResponse> => {
  const results = await Promise.all([
    (await axiosInstance.get<WalletTokensResponse>('/balance', { params: { walletAddress: walletAddress } })).data,
    evmWalletUtils.getNativeTokenBalances(walletAddress),
  ]);

  const newBalances = results[0].map((token) => {
    const newTokenBalance = results[1].find((newToken) => newToken.tokenSymbol === token.symbol && token.isNative);
    if (newTokenBalance) {
      return {
        ...token,
        amount: newTokenBalance.balance,
        fiatAmount: BNDecimals(formatEther(newTokenBalance.balance)).times(token.price).toString(),
      };
    }
    return token;
  });

  const availableTokens = useStore.getState().availableTokens;

  // create wallet token in case BE doesn't return the native token
  for (let i = 0; i < results[1].length; i++) {
    const nativeTokenBalance = results[1][i];
    const existingToken = newBalances.find((a) => a.symbol === nativeTokenBalance.tokenSymbol && a.isNative);
    if (!existingToken) {
      const availableToken = availableTokens?.find((a) => a.symbol === nativeTokenBalance.tokenSymbol && a.isNative);
      if (availableToken) {
        newBalances.push({
          ...availableToken,
          amount: nativeTokenBalance.balance,
          fiatAmount: BNDecimals(formatEther(nativeTokenBalance.balance)).times(availableToken.price).toString(),
          wallet: SupportedWallet.EVM,
          isEvm: true,
          usdTotalPrice: BNDecimals(formatEther(nativeTokenBalance.balance)).times(availableToken.price).toNumber(),
          walletAddress,
        });
      }
    }
  }

  return newBalances;
};
// TODO - remove and use getWalletTokensQuery
export const getExtCardanoWalletTokensQuery = async (
  extCardanoWalletAddress: string,
): Promise<WalletTokensResponse> => {
  return (await axiosInstance.get('/balance', { params: { walletAddress: extCardanoWalletAddress } })).data;
};

export const GET_METAMASK_WALLET_TOKENS_QUERY_KEY = 'GET_METAMASK_WALLET_TOKENS_QUERY_KEY';
export const GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY = 'GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY';
