import { queryClient } from '@api/query-client';
import { useEthersSigner } from '@hooks/use-ethers-signer';
import { Box, Button } from '@mui/material';
import { cn } from '@utils/cn';
import { useCallback, useMemo, useRef, useState } from 'react';
import { AnimatedEllipsis } from './animated-ellipsis';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { useIsPaying } from '@hooks/use-is-paying';
import { CALCULATE_FEE_QUERY_KEY } from 'src/contants/calculate-fee';
import { Tooltip } from './tooltip';
import { capture, captureError } from '@utils/metrics';
import { MetricEvents } from '@typings/metric-events';
import { payEvm } from '@utils/evm/pay';
import { providers } from 'ethers';
import { convertEVMHashToLink } from '@utils/transaction-link.util';
import { MELD_NETWORK } from 'src/contants/meld';
import { animated, useSpring } from 'react-spring';
import { WalletToken } from '@typings/wallet-asset.types';
import {
  GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY,
  GET_METAMASK_WALLET_TOKENS_QUERY_KEY,
} from '@api/meld-app/wallet-tokens/wallet-tokens-query';
import { GET_PAYMENTS } from 'src/contants/queries';
import { useEnoughToken } from '@hooks/use-enough-token';
import { Arrow } from './payment-fee';
import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';
import { useCalculateTxFee } from '@hooks/use-calculate-tx-fee';

const CloseCircle = ({ className }: { className: string }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z"
    ></path>
  </svg>
);

const CheckMark = ({ className }: { className: string }) => (
  <svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"
    ></path>
  </svg>
);

export const ConfirmButton = () => {
  const { evmAddress, evmTxFeeData, evmRequiresApproval } = useStore((state) => state.evmData, shallow);
  const {
    failed,
    approving,
    completed,
    initiatedPayment,
    invalidPlan,
    email: globalEmail,
    notEnoughToken: notEnoughNativeToken,
  } = useStore((state) => state.data, shallow);
  const { amount } = useStore((state) => state.inputData, shallow);
  const userTokens = useStore((state) => state.userTokens, shallow);
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const enoughToken = useEnoughToken();
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const setData = useStore((state) => state.setData);
  const [email, setEmail] = useState('');
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const { isLoading: isLoadingTxFee } = useCalculateTxFee();

  const extEvmWallet = useEthersSigner();
  const { wrongEvmNetwork } = useUnsupportedExtNetwork();

  const isPaying = useIsPaying();

  const internalSelectedWalletToken = useMemo(
    () => (selectedWalletToken ? userTokens.find((a) => a.tokenId === selectedWalletToken.tokenId) : undefined),
    [selectedWalletToken, userTokens],
  );

  const onEmailSubmit = useCallback(() => {
    const valid = email?.match(/^\S+@\S+\.\S+$/);
    if (!valid) {
      setInvalidEmail(true);
      setValidEmail(false);
    } else {
      setTimeout(() => {
        setData({ email });
        setInputFocused(false);
      }, 1500);
      setValidEmail(true);
      setInvalidEmail(false);
    }
  }, [email, setData]);

  const onApprovalCompleted = useCallback(() => {
    // bit of a delay to ensure estimation goes through
    setTimeout(() => {
      // recalculate fee
      queryClient.invalidateQueries([CALCULATE_FEE_QUERY_KEY]);
    }, 10000);
  }, []);

  const onAcceptedPayment = useCallback(() => {
    setData({ acceptedPayment: true });
  }, [setData]);

  const handleBridgeClicked = useCallback(async () => {
    // going to bridge or approve
    if (!failed) {
      if (!evmRequiresApproval) {
        capture(MetricEvents.UserCreatesTransaction);
      }

      // update with "approving state"
      if (evmRequiresApproval) setData({ approving: true });
      else setData({ initiatedPayment: true });

      try {
        const { hash } = await payEvm({
          wallet: extEvmWallet as providers.JsonRpcSigner,
          amount,
          maxFeePerGas: evmTxFeeData?.maxFeePerGas,
          maxPriorityFeePerGas: evmTxFeeData?.maxPriorityFeePerGas,
          onCompleteApproval: onApprovalCompleted,
          onAcceptedPayment,
          selectedWalletToken: internalSelectedWalletToken as WalletToken,
        });

        if (hash) {
          setData({
            completed: true,
            explorerUrl: convertEVMHashToLink(hash, selectedWalletToken?.network ?? MELD_NETWORK),
          });
          queryClient.invalidateQueries([GET_PAYMENTS]);
        }
      } catch (err) {
        captureError(err);
        if (String(err).includes('user rejected transaction')) {
          setData({ initiatedPayment: false, approving: false });
        } else {
          setData({ initiatedPayment: false, approving: false, failed: true });
        }
      }
    } else if (failed) {
      capture(MetricEvents.UserClicksTryAgain);
      queryClient.invalidateQueries([GET_METAMASK_WALLET_TOKENS_QUERY_KEY]);
      queryClient.invalidateQueries([GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY]);
      queryClient.invalidateQueries([CALCULATE_FEE_QUERY_KEY]);

      setData({
        startedAt: undefined,
        failed: false,
        initiatedPayment: false,
      });
    }
  }, [
    amount,
    failed,
    evmRequiresApproval,
    evmTxFeeData?.maxFeePerGas,
    evmTxFeeData?.maxPriorityFeePerGas,
    extEvmWallet,
    onApprovalCompleted,
    selectedWalletToken,
    setData,
    internalSelectedWalletToken,
    onAcceptedPayment,
  ]);

  const getButtonText = useCallback(() => {
    return inputFocused ? (
      'Email'
    ) : !email ? (
      'ENTER YOUR EMAIL'
    ) : failed ? (
      'TRY AGAIN'
    ) : approving ? (
      <>
        APPROVING <AnimatedEllipsis />
      </>
    ) : evmRequiresApproval ? (
      'APPROVE PAYMENT'
    ) : initiatedPayment ? (
      <>
        PAYING <AnimatedEllipsis />
      </>
    ) : (
      'PAY APPLICATION FEE'
    );
  }, [approving, failed, evmRequiresApproval, initiatedPayment, email, inputFocused]);

  const showWarningTooltip = useMemo(() => {
    return !evmAddress ? 'Please connect a wallet to continue' : undefined;
  }, [evmAddress]);

  const states = completed ? { opacity: 0 } : { opacity: 1 };
  const buttonSpring = useSpring(states);

  const disabledButton = useMemo(
    () =>
      (isPaying ||
        showWarningTooltip ||
        approving ||
        initiatedPayment ||
        completed ||
        notEnoughNativeToken ||
        !enoughToken ||
        invalidPlan ||
        isLoadingTxFee ||
        wrongEvmNetwork) &&
      !completed &&
      !failed,
    [
      approving,
      completed,
      enoughToken,
      failed,
      initiatedPayment,
      invalidPlan,
      isPaying,
      notEnoughNativeToken,
      showWarningTooltip,
      isLoadingTxFee,
      wrongEvmNetwork,
    ],
  );

  const lowOpacityButton = useMemo(
    () =>
      completed ||
      invalidPlan ||
      notEnoughNativeToken ||
      !enoughToken ||
      wrongEvmNetwork ||
      isLoadingTxFee ||
      (showWarningTooltip && !completed && !failed),
    [
      completed,
      enoughToken,
      failed,
      invalidPlan,
      notEnoughNativeToken,
      showWarningTooltip,
      wrongEvmNetwork,
      isLoadingTxFee,
    ],
  );

  const animatePulse = useMemo(
    () => (isPaying || approving || initiatedPayment) && !completed,
    [approving, completed, initiatedPayment, isPaying],
  );

  const button = useMemo(
    () => (
      <Button
        onClick={
          globalEmail
            ? handleBridgeClicked
            : () => {
                setInputFocused(true);
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 100);
              }
        }
        className={cn(
          'relative h-[59px] w-full rounded-lg bg-meldred font-semibold uppercase tracking-[0.5px] text-white hover:bg-meldred/80',
          disabledButton && globalEmail && 'pointer-events-none',
          lowOpacityButton && globalEmail ? 'opacity-60' : '',
          animatePulse && 'animate-pulse',
          failed && 'bg-meldblack text-meldwhite hover:bg-meldblack/80',
          !globalEmail &&
            'cursor-pointer border-2 border-solid border-meldwhite bg-meldwhite text-meldblack hover:bg-meldwhite hover:text-black',
          inputFocused && 'cursor-auto  bg-transparent hover:bg-transparent [&>span]:hidden',
        )}
      >
        <div className="relative w-full">
          <span
            className={cn(
              'absolute left-[50%] top-[50%] flex w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center transition-all duration-500',
              inputFocused && '-top-[15px] left-[20px] text-xs text-meldwhite',
            )}
          >
            {getButtonText()}
          </span>
          {
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className={cn('pointer-events-none w-full opacity-0', inputFocused && 'pointer-events-auto opacity-100')}
            >
              <input
                value={email}
                onChange={(e) => {
                  setInvalidEmail(false);
                  setEmail(e.target.value);
                }}
                ref={inputRef}
                className="h-[50px] w-full border-none bg-transparent font-semibold text-base text-meldwhite outline-none"
              />
              <Button
                className={cn(
                  'absolute -right-[9px] -top-[2px] m-0 h-[56px] w-[50px] overflow-hidden rounded-lg rounded-bl-none rounded-tl-none bg-meldwhite p-0 transition-all duration-500',
                )}
                type="submit"
                onClick={onEmailSubmit}
              >
                <Arrow
                  className={cn(
                    'text-meldpink absolute top-8 h-6 w-6 -rotate-90 opacity-0 transition-all delay-300',
                    inputFocused && 'top-[30%] opacity-100',
                    (invalidEmail || validEmail) && '-top-11 opacity-0',
                  )}
                />
                <CloseCircle
                  className={cn(
                    'text-meldpink absolute top-[70%] h-6 w-6 -rotate-90 opacity-0 transition-all delay-300',
                    invalidEmail && 'top-[30%] opacity-100',
                  )}
                />
                <CheckMark
                  className={cn(
                    'text-meldpink absolute top-[70%] h-6 w-6 opacity-0 transition-all delay-300',
                    validEmail && 'top-[30%] opacity-100',
                  )}
                />
              </Button>
            </form>
          }
        </div>
      </Button>
    ),
    [
      getButtonText,
      handleBridgeClicked,
      failed,
      disabledButton,
      lowOpacityButton,
      animatePulse,
      onEmailSubmit,
      email,
      inputFocused,
      invalidEmail,
      globalEmail,
      validEmail,
    ],
  );

  const toReturn = useMemo(
    () => (
      <animated.div style={buttonSpring} className={cn(completed && 'pointer-events-none')}>
        {showWarningTooltip && !invalidEmail && !invalidPlan && globalEmail ? (
          <Tooltip withArrow arrowPlacement="top" content={showWarningTooltip}>
            <Box>{button}</Box>
          </Tooltip>
        ) : (
          button
        )}
      </animated.div>
    ),
    [button, showWarningTooltip, buttonSpring, completed, invalidEmail, invalidPlan, globalEmail],
  );

  return toReturn;
};
