import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Lucid, WalletApi } from 'lucid-cardano/dist';
import { Blockfrost } from 'lucid-cardano/dist';

import { ExtCardanoRpcError, ExtCardanoWallet, SupportedCardanoWallet } from './with-cardano-external-wallet.types';
import { WalletInstance } from './with-cardano-external-wallet.types';
import { SupportedWallet } from '../../types/wallet';
import { isNotProdEnv } from '../../utils/environments-helper';
import { useStore } from '../../store/store';
import { Timeout } from 'react-number-format/types/types';
import { uppercaseFirstLetter } from '@utils/format-string.util';
import { shallow } from 'zustand/shallow';
import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import { capture } from '@utils/metrics';
import { MetricEvents } from '@typings/metric-events';

// a random string is needed as key for blockfrost to initialise. the url we use is our own instance of blockfrost
const blockchainProvider = new Blockfrost(import.meta.env.VITE_APP_CARDANO_BLOCKFROST_URL as string, 'random string');

// we've since switched to using Mesh.js' BrowserWallet and they only support certain wallet extensions. The ones set to 'supported:false' are not supported by them. See: https://github.com/MeshJS/mesh/blob/main/packages/module/src/common/constants.ts#L482
// uncomment the lines below to add support for the other wallets, which Mesh.js supports but we've decided not to support them for now.
// please note the wallets that do not support testnet have not been tested.
const SUPPORTED_WALLETS: Array<{ name: SupportedCardanoWallet; testnet?: boolean; supported?: boolean }> = [
  { name: SupportedWallet.ETERNL },
  { name: SupportedWallet.NAMI },
];

const SUPPORTED_NETWORK = import.meta.env.VITE_APP_ENV === 'production' ? 1 : 0;

const ExtCardanoWallets = () => {
  const [state, setState] = useState<{
    networkId: null | number;
    nameExtCardano: SupportedCardanoWallet;
    walletInstance: WalletInstance | null;
    lucid: null | Lucid;
  }>({
    networkId: null,
    nameExtCardano: SupportedWallet.NAMI,
    walletInstance: null,
    lucid: null,
  });

  const setInputData = useStore((state) => state.setInputData);
  const setCardanoData = useStore((state) => state.setCardanoData);
  const setData = useStore((state) => state.setData);

  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);

  const { cardanoConnected, cardanoWallets, cardanoNotBroadcastedAddress, cardanoWalletName } = useStore(
    (state) => state.cardanoData,
    shallow,
  );

  const initialising = useRef(false);

  const { t } = useTranslation();

  const initiatedLucidRef = useRef(false);

  useEffect(() => {
    (async () => {
      if (!initiatedLucidRef.current) {
        // initialise lucid so we can later connect to the external wallet
        initiatedLucidRef.current = true;
        const newLucid = await Lucid.new(blockchainProvider, isNotProdEnv ? 'Preprod' : 'Mainnet');
        setState((oldState) => ({ ...oldState, lucid: newLucid }));
      }
    })();
  }, []);

  const saveConnectedWalletToLocalStorage = (wallet: string) => {
    try {
      localStorage.setItem('meld-cardano-external-wallet', wallet);
    } catch {
      console.log('Local storage not available');
    }
  };
  const getConnectedWalletFromLocalStorage = (): string | null => {
    try {
      return localStorage.getItem('meld-cardano-external-wallet');
    } catch {
      console.log('Local storage not available');
    }
    return null;
  };

  const previouslyConnnectedWallet = useMemo(() => getConnectedWalletFromLocalStorage(), []);

  const resetAllCardanoData = useCallback(() => {
    if (selectedWalletToken?.chainType === NetworkChainType.CARDANO) {
      setData({ transactionCost: '', notEnoughToken: false });
      setInputData({ inputError: null });
    }
  }, [selectedWalletToken?.chainType, setData, setInputData]);

  const onDisconnect = useCallback(() => {
    localStorage.removeItem('meld-cardano-external-wallet');
    setCardanoData({
      cardanoConnected: false,
      cardanoAddress: null,
      cardanoConnecting: false,
      cardanoNotBroadcastedAddress: null,
      cardanoWallet: null,
    });

    setState((oldState) => ({
      ...oldState,
      networkId: null,
    }));
    resetAllCardanoData();
  }, [setCardanoData, resetAllCardanoData]);

  const handleApiRequestError = useCallback(
    (error: ExtCardanoRpcError) => {
      // wallet disconnected
      if (error.code === -3) {
        onDisconnect();
      }
      // eternl throws this error if you try to connnect to it but without an account/address connected within the extension
      else if (error.message === 'no account set') {
        toast.warn(t('externalWallets.connectEternl'), {
          autoClose: 5000,
        });
        onDisconnect();
      }
      // eternls throws this error if you connect and then change your account.
      else if (error.message === 'account changed') {
        toast.info(t('externalWallets.changedEternl'), { autoClose: 5000 });
        onDisconnect();
      }
      // eternls throws this error if you disconnect our dapp
      else if ((error.message = 'not connected')) {
        onDisconnect();
      }

      return false;
    },
    [onDisconnect, t],
  );

  const verifyNetworkId = useCallback(async () => {
    try {
      const currentNetworkId = await state.walletInstance?.getNetworkId();

      // if not supported network
      if (state.networkId !== currentNetworkId && currentNetworkId !== SUPPORTED_NETWORK) {
        if (currentNetworkId !== undefined) setState((oldState) => ({ ...oldState, networkId: currentNetworkId }));

        setCardanoData({ cardanoWrongNetwork: true });

        // shows wrong network message for 5 seconds
        setTimeout(() => {
          setCardanoData({ cardanoWrongNetwork: false });
        }, 5000);

        onDisconnect();

        return false;
      } else if (currentNetworkId === SUPPORTED_NETWORK && state.networkId !== currentNetworkId) {
        setCardanoData({ cardanoWrongNetwork: false });
        setState((oldState) => ({ ...oldState, networkId: currentNetworkId }));
        return true;
      }

      return true;
    } catch (error) {
      handleApiRequestError(error as ExtCardanoRpcError);
    }
  }, [handleApiRequestError, state.networkId, setCardanoData, onDisconnect, state.walletInstance]);

  const updateAddress = useCallback(async () => {
    try {
      const address = await state.lucid?.wallet.address();

      // broadcast new address that will be picked up in use-wallets-register-and-synced.ts
      if (address && address !== cardanoNotBroadcastedAddress) {
        setCardanoData({ cardanoNotBroadcastedAddress: address });
        resetAllCardanoData();
      }
    } catch (error) {
      // we don't need this here as it exists in verifyNetworkId - it will catch the same errors
      //handleApiRequestError((error as ExtCardanoRpcError).code, getErrorMessage(error));
    }
  }, [setCardanoData, cardanoNotBroadcastedAddress, state.lucid, resetAllCardanoData]);

  const connectWrapper = useCallback(
    async (walletName: SupportedCardanoWallet, autoConnecting: boolean) => {
      if (state.lucid) {
        try {
          setCardanoData({ cardanoWalletName: walletName, cardanoConnecting: true });
          const api = await window.cardano[walletName].enable();
          const wallet = state.lucid.selectWallet(api as WalletApi);
          setCardanoData({ cardanoWallet: wallet, cardanoConnected: true });
          saveConnectedWalletToLocalStorage(walletName);
          setState((oldState) => ({
            ...oldState,
            nameExtCardano: walletName,
            walletInstance: api as WalletInstance,
            lucid: wallet,
          }));
          !autoConnecting && capture(MetricEvents.UserConnectsCardanoWallet, { wallet: walletName });
        } catch (error) {
          setCardanoData({ cardanoConnecting: false, cardanoWalletName: null });
          console.error(error);
          handleApiRequestError(error as Error);
        }
      }
    },
    [handleApiRequestError, setCardanoData, state.lucid],
  );

  // creates an array with all installed wallet extensions
  const fetchInjectedWallets = useCallback(() => {
    const result: ExtCardanoWallet[] = [];
    const cardano = window.cardano;

    for (const i in cardano) {
      const p = cardano[i];
      if (
        p.apiVersion !== null &&
        p.icon != null &&
        p.name !== null &&
        // NuFi was once called ccvault - if we don't do this we end up with 2 NuFis
        result.findIndex((val) => val.name === p.name) === -1
      ) {
        const externalWallet = SUPPORTED_WALLETS.filter((wallet) => wallet.name === i).shift();
        if (
          externalWallet &&
          externalWallet.supported !== false &&
          (SUPPORTED_NETWORK === 1 || (SUPPORTED_NETWORK === 0 && externalWallet.testnet !== false))
        ) {
          result.push({
            version: p.apiVersion,
            icon: p.icon,
            name: uppercaseFirstLetter(p.name),
            key: i as SupportedCardanoWallet,
          });
        }
      }
    }
    setCardanoData({ cardanoWallets: result });
    return result;
  }, [setCardanoData]);

  /*
   * - Checks which external wallets the user previously connected to, connnects to the prefferred one if it exists.
   */
  const init = useCallback(
    async (walletsExtCardano: ExtCardanoWallet[]) => {
      const connectedTo: SupportedCardanoWallet[] = [];
      let hasAwaitedPromises = false;
      try {
        if (walletsExtCardano.length && !cardanoConnected) {
          const toAwait: Array<Promise<boolean>> = [];

          for (let i = 0; i < walletsExtCardano.length; i++) {
            const wallet = walletsExtCardano[i];

            // we check if its enabled to automatically connnect on page refresh...so we do this and if it is enabled then we call the connectWrapper below (we don't just call connectWrapper because if no connection has ever been established the user will be prompted to connect to the wallet extension without having clicked anything)
            toAwait.push((window?.cardano?.[wallet.key] ?? '').isEnabled());
          }

          try {
            // this is a hack because for some users Promise.all doesn't resolve, nor rejects and nowhere does this code (init()) throw an error.
            setTimeout(() => {
              if (!hasAwaitedPromises) {
                setCardanoData({ cardanoLoaded: true });
              }
            }, 200);

            const results = await Promise.all(toAwait);

            hasAwaitedPromises = true;
            results.forEach(
              (val, index) => val && connectedTo.push(walletsExtCardano[index].key as SupportedCardanoWallet),
            );
          } catch (err) {
            console.error(err);
          }

          // connect to the previously last used wallet
          // if the user connects with Nami but later uninstalls or disables our app's access it won't be in 'newConnectTo' and hence we won't try to connect to it
          if (
            !cardanoConnected &&
            previouslyConnnectedWallet &&
            connectedTo.includes(previouslyConnnectedWallet as SupportedCardanoWallet) &&
            state.lucid
          ) {
            await connectWrapper(previouslyConnnectedWallet as SupportedCardanoWallet, true);
          }

          setCardanoData({ cardanoLoaded: true });
        }
      } catch (err) {
        console.error(err);
      }
    },
    [connectWrapper, previouslyConnnectedWallet, cardanoConnected, setCardanoData, state.lucid],
  );

  useEffect(() => {
    if (window.cardano && cardanoWallets.length === 0 && !initialising.current && state.lucid) {
      initialising.current = true;
      const injectedWallets = fetchInjectedWallets();
      init(injectedWallets);
    }
  }, [fetchInjectedWallets, init, cardanoWallets, state.lucid]);

  // poll networkId and address to see if either has changed
  useEffect(() => {
    let intervalId: Timeout;

    if (cardanoConnected && state.lucid?.wallet && cardanoWalletName) {
      intervalId = setInterval(async () => {
        if (await verifyNetworkId()) updateAddress();
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [verifyNetworkId, updateAddress, state, cardanoConnected, cardanoWalletName]);

  useEffect(() => {
    setCardanoData({ cardanoConnectWallet: connectWrapper });
  }, [connectWrapper, setCardanoData]);

  useEffect(() => {
    setCardanoData({ cardanoDisconnectWallet: onDisconnect });
  }, [onDisconnect, setCardanoData]);

  return null;
};

export { ExtCardanoWallets };
