import { useStore } from '@store/store';
import { useQuery } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';
import { useEthersSigner } from './use-ethers-signer';
import { useNativeToken } from './use-native-token';
import { CALCULATE_FEE_QUERY_KEY } from 'src/contants/calculate-fee';
import { useUnsupportedExtNetwork } from '@hooks/use-unsupported-ext-network';
import { captureError } from '@utils/metrics';
import { ValueInputError } from '@typings/ValueInputError';
import { payEvm } from '@utils/evm/pay';
import { providers } from 'ethers';
import { useMemo } from 'react';
import { WalletToken } from '@typings/wallet-asset.types';
import { useEnoughToken } from './use-enough-token';

export const useCalculateTxFee = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const { amount, inputError } = useStore((state) => state.inputData, shallow);
  const { cardanoAddress } = useStore((state) => state.cardanoData, shallow);
  const { evmAddress } = useStore((state) => state.evmData, shallow);
  const userTokens = useStore((state) => state.userTokens, shallow);
  const { email, invalidPlan, loadedUsersBalances } = useStore((state) => state.data, shallow);

  const setData = useStore((state) => state.setData);
  const setEvmData = useStore((state) => state.setEvmData);
  const setInputData = useStore((state) => state.setInputData);
  const enoughToken = useEnoughToken();
  const evmWallet = useEthersSigner();
  const { wrongEvmNetwork, wrongCardanoNetwork } = useUnsupportedExtNetwork();

  const nativeToken = useNativeToken({
    network: selectedWalletToken?.network,
  });

  const internalSelectedWalletToken = useMemo(
    () => (selectedWalletToken ? userTokens.find((a) => a.tokenId === selectedWalletToken.tokenId) : undefined),
    [selectedWalletToken, userTokens],
  );

  const { isLoading, fetchStatus, isRefetching } = useQuery(
    [
      CALCULATE_FEE_QUERY_KEY,
      selectedWalletToken?.tokenId,
      amount,
      cardanoAddress,
      evmAddress,
      wrongEvmNetwork,
      wrongCardanoNetwork,
    ],
    async () => {
      try {
        const { cost, requiresApproval } = await payEvm({
          wallet: evmWallet as providers.JsonRpcSigner,
          amount,
          setFeeData: (feeData) => {
            setEvmData({ evmTxFeeData: feeData });
          },
          selectedWalletToken: internalSelectedWalletToken as WalletToken,
        });
        setEvmData({ evmRequiresApproval: requiresApproval ?? false });
        setData({ approving: false, transactionCost: '' });
        // if user can't cover full amount
        if (!requiresApproval && +(internalSelectedWalletToken?.amount ?? 0) < +amount) {
          setData({
            notEnoughToken: true,
          });
          return null;
        }
        // if native token amount < tx amount
        if (!internalSelectedWalletToken?.isNative) {
          const isOverSendableAmount = Number(cost) > +(nativeToken?.amount ?? 0);
          isOverSendableAmount && setInputData({ inputError: ValueInputError.NOT_ENOUGH_NATIVE_TOKEN });
        }
        setData({
          transactionCost: cost,
        });
        return cost;
      } catch (err) {
        captureError(err);
        if (!inputError) {
          setInputData({
            inputError: ValueInputError.NOT_ENOUGH_NATIVE_TOKEN,
          });
        }
      }
      return null;
    },
    {
      refetchOnWindowFocus: false,
      enabled:
        +amount > 0 &&
        !!email &&
        !invalidPlan &&
        loadedUsersBalances &&
        enoughToken &&
        !!evmAddress &&
        !wrongEvmNetwork,
      refetchOnMount: false,
      staleTime: 0,
      cacheTime: 0,
    },
  );

  return {
    isLoading: (isLoading && fetchStatus !== 'idle') || (isRefetching && fetchStatus !== 'idle'),
  };
};
