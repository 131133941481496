export enum MetricEvents {
  UserCreatesTokenTransaction = 'User confirm token transaction',
  UserCreatesTransaction = 'User confirm transaction',
  UserClicksNftBridge = 'User click NFT bridge',
  UserClicksTokenBridge = 'User click Token bridge',
  UserConnectsEVMWallet = 'User connect EVM wallet',
  UserConnectsCardanoWallet = 'User connect Cardano wallet',
  UserConnectsPaperWallet = 'User connect Paper wallet',
  UserClicksBridgeAgain = 'User click Bridge Again',
  UserClicksTryAgain = 'User click Try Again',
}
