import { useStore } from '@store/store';
import { BigNumber, providers } from 'ethers';
import { formatBytes32String, formatEther, parseUnits } from 'ethers/lib/utils';
import { approveErc20, estimateApproveErc20, hasAllowanceErc20 } from './evmTransactionUtils';
import evmContractUtils from './evmContractUtils';
import MELDPaymentGatewayABI from '../../abi/MELDPaymentGateway.json';
import { WalletToken } from '@typings/wallet-asset.types';
import { CONFIG } from 'src/contants/config';

export const payEvm = async ({
  amount,
  wallet,
  onCompleteApproval,
  maxFeePerGas,
  maxPriorityFeePerGas,
  setFeeData,
  onAcceptedPayment,
  selectedWalletToken,
}: {
  amount: string;
  wallet: providers.JsonRpcSigner;
  onCompleteApproval?: () => void;
  // values received from estimating the cost
  maxFeePerGas?: BigNumber;
  maxPriorityFeePerGas?: BigNumber;
  // pass if estimating cost
  setFeeData?: (data: { maxFeePerGas: BigNumber; maxPriorityFeePerGas: BigNumber }) => void;
  onAcceptedPayment?: () => void;
  selectedWalletToken: WalletToken;
}) => {
  const { data } = useStore.getState();
  const { email } = data;
  const encodedEmail = formatBytes32String(email as string);

  // if calculating the cost then we generate the fee data, else we expect it to be passed to the function as param
  const feeData = setFeeData
    ? await wallet.getFeeData()
    : {
        maxFeePerGas,
        maxPriorityFeePerGas,
      };

  const amountWei = parseUnits(amount, selectedWalletToken?.decimals ?? 18).toString();

  let cost = BigNumber.from(0);

  const needsAllowance = !(await hasAllowanceErc20({
    signer: wallet,
    spender: CONFIG.contractAddress,
    value: amountWei,
    tokenAddress: selectedWalletToken?.contract ?? '',
  }));

  // approve erc20 transfer
  if (onCompleteApproval) {
    const approveTx = await approveErc20({
      signer: wallet,
      spender: CONFIG.contractAddress,
      value: amountWei, //ethers.constants.MaxUint256.toString(),
      tokenAddress: selectedWalletToken?.contract ?? '',
      ...feeData,
    });

    if (approveTx) {
      onCompleteApproval();
      return { approved: true };
    }
  } else {
    // check if user has allowance
    const approvalCost = needsAllowance
      ? await estimateApproveErc20({
          signer: wallet,
          spender: CONFIG.contractAddress,
          value: amountWei, //ethers.constants.MaxUint256.toString(),
          tokenAddress: selectedWalletToken?.contract ?? '',
        })
      : null;
    // needs to approve, calculate costs
    if (needsAllowance && approvalCost) {
      if (setFeeData) {
        setFeeData({
          maxFeePerGas: feeData.maxFeePerGas ?? BigNumber.from(0),
          maxPriorityFeePerGas: feeData.maxPriorityFeePerGas ?? BigNumber.from(0),
        });
      }
      const transactionCost = formatEther(approvalCost.mul(feeData.maxFeePerGas as BigNumber));

      // return costs
      return {
        requiresApproval: true,
        cost: transactionCost,
      };
    }
  }

  // user has approval
  const contract = await evmContractUtils.connect(wallet, CONFIG.contractAddress, MELDPaymentGatewayABI);

  if (setFeeData) {
    cost = await contract.estimateGas['pay'](encodedEmail, selectedWalletToken?.contract, amountWei);
  } else {
    // generate tx
    const tx = await contract['pay'](encodedEmail, selectedWalletToken?.contract, amountWei);
    onAcceptedPayment?.();
    await tx.wait(1);
    return { hash: tx.hash };
  }

  const transactionCost = formatEther(cost.mul(feeData.maxFeePerGas as BigNumber));

  setFeeData?.({
    maxFeePerGas: feeData.maxFeePerGas ?? BigNumber.from(0),
    maxPriorityFeePerGas: feeData.maxPriorityFeePerGas ?? BigNumber.from(0),
  });

  return { cost: transactionCost };
};
