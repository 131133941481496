import { formatUnits } from 'ethers/lib/utils';
import evmContractUtils from './evm/evmContractUtils';
import MELDPaymentGatewayABI from '../abi/MELDPaymentGateway.json';
import { Event, providers } from 'ethers';
import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { CONFIG } from 'src/contants/config';
import { convertEVMHashToLink } from './transaction-link.util';
import { isNotProdEnv } from './environments-helper';

// TODO - update if multiple networks are supported
const INITIAL_BLOCK = isNotProdEnv ? 2133776 : 20535846;
const MAX_REQUESTED_BLOCKS = 2000;

type EventWithTimestamp = Event & { timestamp: number };

export const getPayments = async (
  wallet: providers.JsonRpcSigner,
  availableTokens: AvailableToken[],
  startingBlock = INITIAL_BLOCK,
) => {
  const contract = await evmContractUtils.connect(wallet, CONFIG.contractAddress, MELDPaymentGatewayABI);

  const blockNumber = await wallet.provider.getBlockNumber();

  if (startingBlock > blockNumber) {
    return { data: [], blockNumber: blockNumber };
  }

  const { chainId } = await wallet.provider.getNetwork();
  const networkName = CONFIG.supportedEvmNetworks.find((a) => a.id === chainId)?.name;

  let blocksToLookFor = startingBlock < blockNumber ? blockNumber - startingBlock : 1;
  let initialBlock = startingBlock;
  let finalBlock = startingBlock + (blocksToLookFor >= MAX_REQUESTED_BLOCKS ? MAX_REQUESTED_BLOCKS : blocksToLookFor);
  let allEvents: Event[] = [];
  while (blocksToLookFor) {
    const events = await contract.queryFilter(
      'PaymentReceived',
      initialBlock, // covers use-case of looking for just 1 block, the final one
      finalBlock > blockNumber ? blockNumber : finalBlock,
    );
    allEvents = allEvents.concat(events);
    blocksToLookFor -= finalBlock - initialBlock;
    initialBlock = finalBlock;
    finalBlock += blocksToLookFor >= MAX_REQUESTED_BLOCKS ? MAX_REQUESTED_BLOCKS : blocksToLookFor;
  }

  for (let i = 0; i < allEvents.length; i++) {
    const block = await allEvents[i].getBlock();
    // @ts-expect-error 2353
    allEvents[i] = { ...allEvents[i], timestamp: block.timestamp };
  }

  return {
    data: (allEvents as Array<EventWithTimestamp>)
      .filter((a) => a.args?.[0] === wallet._address)
      .map((event) => {
        const tokenAddress = event.args?.[2]?.toLowerCase();
        const token = availableTokens.find(
          (a) =>
            a.contract === tokenAddress &&
            a.network === CONFIG.supportedEvmNetworks.find((a) => a.id === chainId)?.name,
        );
        return {
          logo: token?.icon,
          symbol: token?.symbol,
          amount: Number(formatUnits(event.args?.amount.toString(), token?.decimals)),
          timestamp: event.timestamp,
          explorerUrl: convertEVMHashToLink(event.transactionHash, networkName as string),
        };
      })
      .sort((a, b) => b.timestamp - a.timestamp),
    blockNumber: finalBlock,
  };
};
