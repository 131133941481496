import { useStore } from '@store/store';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

export const useIsPaying = () => {
  const { startedAt, completed, failed } = useStore((state) => state.data, shallow);

  const isPaying = useMemo(() => startedAt && !completed && !failed, [startedAt, completed, failed]);

  return isPaying;
};
