import { useEvmConnection } from '@hooks/use-evm-connection';
import { ConnectButton } from '@components/user-menu/connect-button-v2';
import { CardanoPopup } from './cardano-popup';
import { animated, useSpring } from 'react-spring';
import { useStore } from '@store/store';
import { cn } from '@utils/cn';
import { useLoadedInitialBeData } from '@hooks/use-loaded-initial-be-data';
import { WalletMenu } from '@components/user-menu/wallet-menu';
import { useCallback, useMemo, useState } from 'react';
import { usePayments } from '@hooks/use-payments';
import { networks } from '@utils/networks/networks';
import { useChainId } from 'wagmi';
import { CONFIG } from 'src/contants/config';
import isMobile from 'is-mobile';

const _isMobile = isMobile();

export const ConnectButtonMenu = () => {
  const paying = !!useStore((state) => state.data.initiatedPayment);
  const approving = !!useStore((state) => state.data.approving);
  const explorerUrl = !!useStore((state) => state.data.explorerUrl);
  const [walletMenuOpen, setWalletMenuOpen] = useState<HTMLElement | null>(null);

  const loadedInitialBeData = useLoadedInitialBeData();
  const { isLoading, data } = usePayments();

  const { address: evmAddress, open: evmOpen, isConnecting: evmIsConnecting, disconnect } = useEvmConnection();

  const chainId = useChainId();

  const network = useMemo(() => CONFIG.supportedEvmNetworks.find((a) => a.id === chainId)?.name, [chainId]);

  const wrapperSpring = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, delay: _isMobile ? 1200 : 2500 });

  const onWalletMenuClosed = useCallback(() => {
    setWalletMenuOpen(null);
  }, []);

  const onTriggerMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setWalletMenuOpen(event.currentTarget);
  }, []);

  return (
    <div className={cn(!loadedInitialBeData && 'pointer-events-none opacity-0')}>
      <animated.div
        className={cn((paying || approving) && !explorerUrl && 'pointer-events-none')}
        style={wrapperSpring}
      >
        <ConnectButton
          onTriggerMenu={onTriggerMenu}
          evmAddress={evmAddress}
          onClickConnect={(e: React.MouseEvent<HTMLElement>) => {
            if (evmAddress) {
              onTriggerMenu(e);
            } else {
              evmOpen();
            }
          }}
          connectingEvm={evmIsConnecting}
        />
        <div className="absolute">
          <CardanoPopup />
          <WalletMenu
            isLoading={isLoading}
            key={'wallet-menu'}
            walletExplorerUrl={`${networks[network as string]?.explorerUrl}/address/${evmAddress}`}
            data={data}
            open={walletMenuOpen}
            handleClose={onWalletMenuClosed}
            address={evmAddress}
            isConnected={!!evmAddress}
            onDisconnect={() => {
              disconnect();
            }}
          />
        </div>
      </animated.div>
    </div>
  );
};
