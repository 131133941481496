import { useStore } from '@store/store';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import { useWalletTokens } from './use-wallet-tokens';

export const useEnoughToken = () => {
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const { evmAddress } = useStore((state) => state.evmData, shallow);
  const { isLoadingEvm } = useWalletTokens();
  const userTokens = useStore((state) => state.userTokens, shallow);
  const { amount } = useStore((state) => state.inputData, shallow);

  const internalSelectedWalletToken = useMemo(
    () => (selectedWalletToken ? userTokens.find((a) => a.tokenId === selectedWalletToken.tokenId) : undefined),
    [selectedWalletToken, userTokens],
  );

  return internalSelectedWalletToken && evmAddress && !isLoadingEvm
    ? +(internalSelectedWalletToken?.amount ?? 0) >= +amount
    : true;
};
