import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@utils/cn';
import { CONFIG, EXCHANGE_RATE_UPDDATE_IN_SECONDS } from 'src/contants/config';
import { useStore } from '@store/store';
import { shallow } from 'zustand/shallow';
import { formatCryptoBalance, formatCurrency } from '@utils/format-currency';
import { queryClient } from '@api/query-client';
import { GET_AVAILABLE_TOKENS_QUERY_KEY } from '@api/meld-app/available-tokens/available-tokens-query';
import { Timeout } from 'react-number-format/types/types';
import { animated, useSpring } from 'react-spring';
import { bankersRounding } from '@utils/bankers-rounding';

type Props = {
  offsetOfDetailsComponent: number;
};

/**
 * @remarks
 * Price of stable coins is hardcoded to $1 to yield a final pretty number.
 */
export const BillDetails = ({ offsetOfDetailsComponent }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [positionedRelative, setPositionedRelative] = useState(true);
  const setData = useStore((state) => state.setData);
  const setInputData = useStore((state) => state.setInputData);
  const selectedWalletToken = useStore((state) => state.selectedWalletToken, shallow);
  const inputData = useStore((state) => state.inputData, shallow);
  const { completed, acceptedPayment } = useStore((state) => state.data, shallow);
  const availableTokens = useStore((state) => state.availableTokens, shallow);
  const [countDown, setCountdown] = useState(EXCHANGE_RATE_UPDDATE_IN_SECONDS);

  const plan = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const planName = searchParams.get('plan_name');

    const plan = CONFIG.plans.find((a) => a.name.toLowerCase() === planName?.toLowerCase());

    if (!plan) {
      // set invalid plan so we show an error message
      setData({ invalidPlan: true });
    }

    return plan;
  }, [setData]);

  useEffect(() => {
    setCountdown(EXCHANGE_RATE_UPDDATE_IN_SECONDS);
  }, [selectedWalletToken]);

  const calculateExchangeRate = useCallback(() => {
    if (plan && selectedWalletToken) {
      const { price } = plan;
      setData({ amountInFiat: price });
      const availableToken = availableTokens?.find((a) => a.tokenId === selectedWalletToken.tokenId);
      const priceOfToken = availableToken?.isStableCoin ? '1' : availableToken?.price;

      const priceInToken =
        price /
        +formatCurrency(+(priceOfToken ?? '0'), false)
          .replaceAll(',', '')
          .replaceAll('$', '');

      setInputData({ amount: bankersRounding(priceInToken).toString() });

      return priceInToken;
    }
  }, [selectedWalletToken, plan, setInputData, availableTokens, setData]);

  useEffect(() => {
    const priceInToken = calculateExchangeRate();
    let interval: Timeout;

    if (priceInToken && !acceptedPayment) {
      interval = setInterval(() => {
        setCountdown((val) => {
          if (val === 1) {
            queryClient.invalidateQueries([GET_AVAILABLE_TOKENS_QUERY_KEY]);
            return EXCHANGE_RATE_UPDDATE_IN_SECONDS;
          }
          return (val -= 1);
        });
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [calculateExchangeRate, acceptedPayment]);

  useEffect(() => {
    const calculatePosition = () => {
      const windowHeight = window.innerHeight;
      if (windowHeight - 120 > offsetOfDetailsComponent) {
        setPositionedRelative(false);
      } else setPositionedRelative(true);
    };

    calculatePosition();

    window.addEventListener('resize', calculatePosition);

    return () => window.removeEventListener('resize', calculatePosition);
  }, [offsetOfDetailsComponent]);

  const states = completed ? { opacity: 0, y: 20 } : { opacity: 1, y: 0 };
  const spring = useSpring(states);

  const statesExchangeRate = acceptedPayment ? { opacity: 0 } : { opacity: 1 };
  const springExchangeRate = useSpring(statesExchangeRate);

  if (!plan) return null;

  return (
    <animated.div
      style={spring}
      ref={ref}
      className={cn(
        'group relative mb-[33px] px-4 transition-all md:block',
        !positionedRelative && 'fixed bottom-4 mb-0',
        completed && 'pointer-events-none',
      )}
    >
      <p className="text-left font-semibold text-xs leading-[20px] tracking-[0.72px] text-meldwhite/60 transition-all hover:opacity-80 md:text-center">
        Your bill is ${plan?.price}. At the current rate of 1 {selectedWalletToken?.symbol} ≈{' '}
        {formatCurrency((plan?.price ?? 0) / +inputData.amount, false)}, please pay{' '}
        {formatCryptoBalance(inputData.amount, selectedWalletToken?.symbol, true)}.
        <br className="hidden md:block" />
        <animated.span style={springExchangeRate} className={cn('inline', acceptedPayment && 'pointer-events-none')}>
          {' '}
          The exchange rate will update in {countDown} {countDown === 1 ? 'second' : 'seconds'}.
        </animated.span>
      </p>
    </animated.div>
  );
};
