import { cn } from '@utils/cn';
import { animated, useSpring } from 'react-spring';
import ShareIcon from '../../assets/share.svg?react';
import { memo, useEffect, useState } from 'react';

type Props = {
  data?: { explorerUrl: string; amount: string };
};

export const SuccessMessage = memo(({ data }: Props) => {
  const [dataToUse, setDataToUse] = useState({ explorerUrl: '' });

  const successAnimationState = data
    ? { from: { opacity: 0, y: 10 }, to: { opacity: 1, y: 0 } }
    : { from: { opacity: 1, y: 0 }, to: { opacity: 0, y: 20 } };

  const bridgeSuccess1Spring = useSpring({
    ...successAnimationState,
    delay: data ? 550 : 350,
  });

  const bridgeSuccess2Spring = useSpring({
    ...successAnimationState,
    delay: data ? 750 : 200,
  });

  useEffect(() => {
    if (data) {
      setDataToUse({
        amount: data.amount,
        explorerUrl: data.explorerUrl,
      });
    }
  }, [data]);

  return (
    <animated.div
      className={cn(
        'opacity-1 absolute flex max-w-[258px] flex-col flex-wrap pt-10 md:block md:max-w-none',
        data && 'z-10',
        !data && 'pointer-events-none -z-10',
      )}
    >
      <animated.div
        style={bridgeSuccess1Spring}
        className="text-center font-semibold text-xl uppercase tracking-[0.72px] text-meldgreen opacity-0 md:whitespace-nowrap md:text-lg"
      >
        SUCCESSFULLY PAID <span className="text-meldblack">{dataToUse.amount}</span>
      </animated.div>
      <animated.div style={bridgeSuccess2Spring} className={'flex w-full justify-center opacity-0'}>
        <animated.a
          className="mt-3 block w-max cursor-pointer no-underline md:mt-1"
          target="_blank"
          href={dataToUse.explorerUrl}
        >
          <div className="flex items-center justify-center gap-1 [&_path]:fill-meldwhite">
            <div className="font-semibold text-xs text-meldwhite">VIEW IN EXPLORER</div>
            <ShareIcon className="h-4 w-4" />
          </div>
        </animated.a>
      </animated.div>
    </animated.div>
  );
});
