import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { MetricEvents } from '@typings/metric-events';

export const initMetricCollection = () => {
  Sentry.init({
    environment:
      window.location.host.includes('local-app.meld.com') || window.location.host.includes('localhost')
        ? 'development'
        : import.meta.env.VITE_APP_ENV || 'development',
    dsn: 'https://1f65182a05b8232109ac641b2ad17683@o4505550632976384.ingest.us.sentry.io/4507740724658176',
    attachStacktrace: true,
  });
  posthog.init(import.meta.env.VITE_APP_METRICS_KEY as string, {
    api_host: 'https://eu.i.posthog.com',
    autocapture: false,
  });
};

export const capture = <T>(event: MetricEvents, metadata?: T) =>
  metadata ? posthog.capture(event, metadata) : posthog.capture(event);

export const captureError = <T>(error: T) => {
  console.error(error);
  Sentry.captureException(error);
};
