import { ReactNode } from 'react';
import { Header } from './header';
import { animated, useSpring } from 'react-spring';
import { useLoadedInitialBeData } from '@hooks/use-loaded-initial-be-data';
import { cn } from '@utils/cn';
import isMobile from 'is-mobile';

type Props = {
  children: ReactNode;
};

const _isMobile = isMobile();

export const Layout = ({ children }: Props) => {
  const loadedInitialBeData = useLoadedInitialBeData();

  const style = useSpring(
    loadedInitialBeData ? { from: { opacity: 0 }, to: { opacity: 1 }, delay: _isMobile ? 1000 : 2500 } : {},
  );

  return (
    <div className="p-6 md:p-[50px]">
      <Header />
      <animated.div
        style={style}
        className={cn(
          'mx-auto mt-[58px] flex w-full max-w-[1130px] flex-col items-center px-0 opacity-0 md:mt-[160px] md:w-auto md:px-[78px] min-[1370px]:px-0',
        )}
      >
        {children}
      </animated.div>
    </div>
  );
};
