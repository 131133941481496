import { Button } from '@mui/material';
import { shortenAddress } from '../../utils/shorten-address';
import { cn } from '../../utils/cn';
import { animated, useSpring } from 'react-spring';
import { CONNECT_BUTTON_DEFAULT_STYLE } from 'src/contants/connect-button';
import { AnimatedEllipsis } from '@components/animated-ellipsis';
import { TokenIcon } from '@components/token-icon';
import { networks } from '@utils/networks/networks';
import { MELD_NETWORK } from 'src/contants/meld';
import isMobile from 'is-mobile';
import { useEffect, useState } from 'react';

type Props = {
  onClickConnect: (e: React.MouseEvent<HTMLElement>) => void;
  connectingEvm: boolean;
  className?: string;
  evmAddress?: string;
  onTriggerMenu: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const _isMobile = isMobile();

export const ConnectButton = ({ evmAddress, onClickConnect, connectingEvm }: Props) => {
  const [isMobile, setIsMobile] = useState(_isMobile);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth < 550);
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  // this button always shows, either as "CONNECT" to connect any wallet or as a Cardano button
  const firstButtonStates = {
    width: isMobile ? (connectingEvm ? 165 : !evmAddress ? 120 : 150) : connectingEvm ? 220 : !evmAddress ? 180 : 220,
  };

  const firstButtonSpring = useSpring(firstButtonStates);

  return (
    <div className="flex h-max items-center">
      <div
        className={cn(
          'relative flex h-max items-center overflow-hidden rounded-lg',
          evmAddress && 'shadow-[inset_0px_0px_0px_1px_#D9D9D9]',
        )}
      >
        <animated.div style={firstButtonSpring}>
          <Button
            onClick={onClickConnect}
            variant="outlined"
            className={cn(
              CONNECT_BUTTON_DEFAULT_STYLE,
              evmAddress && 'rounded-r-none border-0 lowercase',
              (connectingEvm || !evmAddress) && 'uppercase',
            )}
          >
            {(connectingEvm || evmAddress) && (
              <TokenIcon
                boxShadow={false}
                innerWrapperClassName="h-[24px] w-[24px] -translate-x-1"
                imgSrc={networks[MELD_NETWORK]?.darkIcon ?? ''}
              />
            )}
            {connectingEvm ? (
              <>CONNECTING {_isMobile ? '' : <AnimatedEllipsis />}</>
            ) : !evmAddress ? (
              'CONNECT'
            ) : (
              shortenAddress(evmAddress as string, isMobile ? 4 : 6, isMobile ? 4 : 6)
            )}
          </Button>
        </animated.div>
      </div>
    </div>
  );
};
