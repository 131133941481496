import { cn } from '@utils/cn';
import { animated, config, useTrail } from 'react-spring';

type Props = { className?: string };

export const AnimatedEllipsis = ({ className }: Props) => {
  const trails = useTrail(3, {
    from: { opacity: 1 },
    to: { opacity: 0.1 },
    loop: true,
    config: config.slow,
  });

  return (
    <div className={cn('ml-1 flex gap-1 text-xl', className)}>
      {trails.map((props) => (
        <animated.div className={'text-meldwhite opacity-10'} style={props}>
          .
        </animated.div>
      ))}
    </div>
  );
};
