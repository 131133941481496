import { NetworkChainType } from '@api/meld-app/networks/networks.types';
import {
  GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY,
  GET_METAMASK_WALLET_TOKENS_QUERY_KEY,
  getExtCardanoWalletTokensQuery,
  getMetamaskWalletTokensQuery,
} from '@api/meld-app/wallet-tokens/wallet-tokens-query';
import { WalletTokensResponse } from '@api/meld-app/wallet-tokens/wallet-tokens.types';
import { useEthersSigner } from '@hooks/use-ethers-signer';
import { useStore } from '@store/store';
import { useQuery } from '@tanstack/react-query';
import { WalletToken } from '@typings/wallet-asset.types';
import { formatUnits } from 'ethers/lib/utils';
import { useEffect, useMemo } from 'react';
import { CONFIG } from 'src/contants/config';
import { shallow } from 'zustand/shallow';

const formatAmounts = async (promise: Promise<WalletTokensResponse>) => {
  const tokens = await promise;
  return tokens.map((a) => ({ ...a, amount: formatUnits(a.amount, a.decimals) }));
};

export const useWalletTokens = () => {
  const { cardanoAddress } = useStore((state) => state.cardanoData, shallow);
  const evmAddress = useStore((state) => state.evmData.evmAddress);
  const setUserTokens = useStore((state) => state.setUserTokens);
  const setData = useStore((state) => state.setData);
  const availableTokens = useStore((state) => state.availableTokens, shallow);

  const evmSigner = useEthersSigner();

  const {
    isLoading: isLoadingEvm,
    fetchStatus: fetchStatusEvm,
    data: dataEvm,
    isRefetching: isRefetchingEvm,
  } = useQuery(
    [GET_METAMASK_WALLET_TOKENS_QUERY_KEY, evmAddress],
    () => formatAmounts(getMetamaskWalletTokensQuery(evmAddress as string)),
    {
      refetchOnWindowFocus: false,
      enabled: !!evmAddress && !!evmSigner,
      refetchOnMount: false,
    },
  );

  const {
    isLoading: isLoadingCardano,
    fetchStatus: fetchStatusCardano,
    isRefetching: isRefetchingCardano,
    data: dataCardano,
  } = useQuery(
    [GET_EXT_CARDANO_WALLET_TOKENS_QUERY_KEY, cardanoAddress],
    () => formatAmounts(getExtCardanoWalletTokensQuery(cardanoAddress as string)),
    {
      refetchOnWindowFocus: false,
      enabled: !!cardanoAddress,
      refetchOnMount: false,
    },
  );

  const userTokens = useMemo(() => {
    const allSupportedTokens = CONFIG.supportedTokens;

    const userTokens = allSupportedTokens
      .map((a) => {
        const evmToken = dataEvm?.find((token) => token.tokenId === a.tokenId);
        const cardanoToken = dataCardano?.find((token) => token.tokenId === a.tokenId);
        const availableToken = availableTokens?.find((token) => token.tokenId === a.tokenId);

        return evmToken ?? cardanoToken ?? (availableToken ? { ...availableToken, amount: '0' } : undefined);
      })
      .filter((a) => a);

    const userNativeTokens: Array<WalletToken> = [];
    for (let i = 0; i < userTokens.length; i++) {
      const userToken = userTokens[i];
      const nativeToken = userToken?.isNative
        ? undefined
        : (userToken?.chainType === NetworkChainType.EVM ? dataEvm : dataCardano)?.find(
            (a) => a?.network === userToken?.network && a?.isNative,
          );

      if (nativeToken && !userNativeTokens.find((a) => a.tokenId === nativeToken?.tokenId)) {
        userNativeTokens.push(nativeToken);
        continue;
      }

      const availableToken = nativeToken
        ? undefined
        : userToken?.isNative
          ? undefined
          : { ...availableTokens?.find((a) => a.isNative && a.network === userToken?.network), amount: '0' };

      if (availableToken && !userNativeTokens.find((a) => a.tokenId === availableToken?.tokenId))
        userNativeTokens.push(availableToken as WalletToken);
    }

    return userTokens.concat(userNativeTokens as WalletToken[]);
  }, [dataEvm, dataCardano, availableTokens]);

  // TODO - update if support for cardano is added
  useEffect(() => {
    setUserTokens(userTokens as Array<WalletToken>);
    if (dataEvm) {
      setData({ loadedUsersBalances: true });
    }
  }, [userTokens, setUserTokens, dataEvm, setData]);

  return {
    isLoadingEvm: isLoadingEvm && fetchStatusEvm !== 'idle',
    fetchStatusEvm,
    dataEvm,
    isRefetchingEvm,
    isLoadingCardano: isLoadingCardano && fetchStatusCardano !== 'idle',
    dataCardano,
    isRefetchingCardano,
    userTokens,
  };
};
