import { useWeb3Modal, useWeb3ModalState } from '@web3modal/wagmi/react';
import { useAccount, useDisconnect } from 'wagmi';
import { useEvmNetwork } from './use-evm-network';

export const useEvmConnection = () => {
  const { open } = useWeb3Modal();
  const { address, isConnecting, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const network = useEvmNetwork();

  /**
   * we rely on this flag to determine whether its actually connecting...
   * otherwise as soon as you connect via WC it switches to "connecting" and if you close the popup
   * it gets stuck with connecting state set to true...
   */
  const { open: isOpen } = useWeb3ModalState();

  return {
    open,
    address,
    isConnected,
    isConnecting: isConnecting && isOpen,
    disconnect,
    network,
  };
};
