import { TransactionRequest } from '@ethersproject/abstract-provider';
import { BigNumber, Signer, Wallet, ethers, providers } from 'ethers';
import { formatEther, parseEther, parseUnits } from 'ethers/lib/utils';

import ERC20ABI from '../../abi/ERC20.json';

import evmContractUtils from './evmContractUtils';

const signTransaction = async (wallet: Wallet, addressTo: string, value: number, externalSigner?: Signer) => {
  const signerToUse = externalSigner ?? wallet;

  const transaction: TransactionRequest = {
    to: addressTo,
    value: parseEther(value.toString()),
  };
  const signedTransaction = await signerToUse.signTransaction(transaction);

  return signedTransaction;
};

/**
 * Function that returns the transaction details
 * @param wallet
 * @param transactionHash
 * @returns transaction & receipt
 */
const getTransactionDetails = async (wallet: Wallet, transactionHash: string) => {
  const transaction = await wallet.provider.getTransaction(transactionHash);
  const receipt = await wallet.provider.getTransactionReceipt(transactionHash);

  return {
    transaction,
    receipt,
  };
};

/**
 * Function that returns the last block gas price
 * @param provider - the provider instance
 * @returns the gas price as BigNumber
 */
export const getLastBlockGasPrice = async (provider: providers.Provider) => {
  const lastBlock = await provider.getBlockNumber();
  const block = await provider.getBlock(lastBlock);

  const gasUsed = BigNumber.from(block.gasUsed);
  const gasLimit = BigNumber.from(block.gasLimit);
  const baseFeePerGas = BigNumber.from(block.baseFeePerGas);

  const gasPrice = gasUsed.mul(baseFeePerGas).div(gasLimit);

  return { gasPrice, gasUsed, gasLimit, baseFeePerGas };
};

/**
 * Function that returns a tuple [transaction cost, lastBaseFeePerGas]
 * @param wallet - the wallet instance
 * @param addressTo - the address to send the transaction to
 * @param value - the value to send
 * @param tokenAddress - the token address
 * @param decimals - the token decimals
 * @returns a tuple [transaction cost, maxFeePerGas, maxPriorityFeePerGas]
 */
const estimateTransactionCost = async ({
  wallet,
  addressTo,
  value,
  tokenAddress,
  decimals,
  functionName,
  externalSigner,
  alreadyFormattedValue,
  isNativeToken,
}: {
  wallet: Wallet;
  externalSigner?: Signer;
  addressTo: string;
  value: number | bigint;
  tokenAddress?: string;
  decimals?: number;
  functionName?: string;
  alreadyFormattedValue?: boolean;
  isNativeToken: boolean;
}): Promise<[string, BigNumber, BigNumber, BigNumber]> => {
  const signerToUse = externalSigner ?? wallet;

  let contract;
  if (tokenAddress && !isNativeToken) {
    contract = await evmContractUtils.connect(signerToUse, tokenAddress, ERC20ABI);
  }
  const { lastBaseFeePerGas } = await signerToUse.getFeeData();

  const formattedValue = alreadyFormattedValue ? value.toString() : parseUnits(value.toString(), decimals ?? 18);
  let gasLimit: BigNumber;
  if (contract) {
    if (functionName) {
      gasLimit = await contract.estimateGas[functionName](addressTo, formattedValue, 0);
    } else {
      gasLimit = await contract.estimateGas.transfer(addressTo, formattedValue);
    }
  } else {
    gasLimit = (await signerToUse.estimateGas({ to: addressTo, value: formattedValue })) || BigNumber.from(21000);
  }

  const transactionCost = formatEther((lastBaseFeePerGas || BigNumber.from(21000)).mul(gasLimit));
  return [transactionCost, lastBaseFeePerGas || BigNumber.from(21000), BigNumber.from(1), gasLimit];
};

export const hasAllowanceErc20 = async ({
  signer,
  spender,
  value,
  tokenAddress,
}: {
  signer: Signer;
  spender: string;
  value: string;
  tokenAddress: string;
}) => {
  const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, signer);
  const owner = await signer.getAddress();
  const allowance = await tokenContract.allowance(owner, spender);
  return BigNumber.from(allowance).gte(BigNumber.from(value));
};

export const estimateApproveErc20 = async ({
  signer,
  spender,
  tokenAddress,
}: {
  signer: Signer;
  spender: string;
  value: string;
  tokenAddress: string;
}) => {
  const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, signer);
  const owner = await signer.getAddress();
  const cost = await tokenContract.estimateGas.allowance(owner, spender);
  return cost;
};

const onApproval = (approveTx: unknown) =>
  new Promise((resolve) => {
    (async () => {
      let resolved = false;
      // this is here in case .wait(2) never resolves
      setTimeout(() => {
        if (!resolved) {
          resolved = true;
          resolve(approveTx);
        }
      }, 35000);
      // @ts-expect-error 18046
      await approveTx.wait(1);
      if (!resolved) {
        resolve(approveTx);
      }
    })();
  });

export const approveErc721 = async ({
  signer,
  spender,
  tokenAddress,
  tokenId,
  maxFeePerGas,
  maxPriorityFeePerGas,
}: {
  signer: Signer;
  spender: string;
  tokenAddress: string;
  tokenId: string;
  maxFeePerGas?: BigNumber | null;
  maxPriorityFeePerGas?: BigNumber | null;
}) => {
  const tokenContract = new ethers.Contract(tokenAddress, ERC721ABI, signer);
  const tx = await tokenContract.approve(spender, tokenId, { maxFeePerGas, maxPriorityFeePerGas });
  return onApproval(tx);
};

const getPendingTransactions = async (wallet: Wallet) => {
  const filter: providers.Filter = {
    fromBlock: 0,
    toBlock: 'latest',
    address: wallet.address,
  };
  const pendingTransactions = await wallet.provider.getLogs(filter);

  return pendingTransactions;
};

export const approveErc20 = async ({
  signer,
  spender,
  value,
  tokenAddress,
  maxFeePerGas,
  maxPriorityFeePerGas,
}: {
  signer: Signer;
  spender: string;
  value: string;
  tokenAddress: string;
  maxFeePerGas?: BigNumber | null;
  maxPriorityFeePerGas?: BigNumber | null;
}) => {
  const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, signer);
  const owner = await signer.getAddress();
  const allowance = (await tokenContract.allowance(owner, spender)) as BigNumber;

  if (allowance.lt(BigNumber.from(value))) {
    const tx = await tokenContract.approve(spender, value, { maxFeePerGas, maxPriorityFeePerGas });
    return onApproval(tx);
  }
};

const evmTransactionUtils = {
  estimateTransactionCost,
  signTransaction,
  getLastBlockGasPrice,
  getPendingTransactions,
  getTransactionDetails,
  approveErc20,
};

export default evmTransactionUtils;
