import { Network, NetworksResponse } from '../../../api/meld-app/networks/networks.types';

export const parseNetworks = (networks: NetworksResponse) => {
  return networks.reduce<Record<string, Network>>((prev, curr) => {
    prev[curr.name] = {
      ...curr,
    };

    return prev;
  }, {});
};
