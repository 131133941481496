import { Lucid } from 'lucid-cardano/dist';

import { INetworkNames, Networks, setEvmNetworks, setNetworkNames, setNetworks } from '@utils/networks/networks';
import {
  ExtCardanoWallet,
  SupportedCardanoWallet,
} from '@components/with-cardano-external-wallets/with-cardano-external-wallet.types';
import { AvailableToken } from '@api/meld-app/available-tokens/available-tokens.types';
import { StateSlice } from '@typings/state-creator.types';
import { setSbAssets, setSbAssetsByContract, setSbAssetsByTokenId, setSbAssetsObj } from '@utils/assets-helper';
import { WalletToken } from '@typings/wallet-asset.types';
import { ValueInputError } from '@typings/ValueInputError';
import { BigNumber } from 'ethers';

type EVMData = {
  evmConnectedChainId?: number | undefined;
  evmRequiresApproval?: boolean;
  evmWalletName?: string;
  evmTxFeeData?: { maxFeePerGas: BigNumber; maxPriorityFeePerGas: BigNumber };
  notBroadcastedExternalEVMWalletAddress: string | null;
  evmAddress: string | null;
  evmAddressRegistered: boolean;
};

type CardanoData = {
  cardanoWrongNetwork?: boolean;
  cardanoAddress: string | null;
  cardanoDisconnectWallet: (() => void) | null;
  cardanoConnectWallet: ((walletName: SupportedCardanoWallet, autoConnecting: boolean) => Promise<void>) | null;
  cardanoWalletRegistered: boolean;
  cardanoWalletName: SupportedCardanoWallet | null;
  cardanoWallet: Lucid | null;
  cardanoConnected: boolean;
  cardanoLoaded: boolean;
  cardanoConnecting: boolean;
  cardanoNotBroadcastedAddress: string | null;
  cardanoWallets: ExtCardanoWallet[];
};

export type Data = {
  // this turns true before startedAt is defined
  // essentially means we fired the popup for the user to accept the tx but they haven't accepted yet
  initiatedPayment: boolean;
  // when user accepts MM tx
  acceptedPayment: boolean;
  approving: boolean;
  startedAt?: number;
  failed: boolean;
  explorerUrl?: string;
  transactionCost: string;
  notEnoughToken: boolean;
  completed: boolean;
  invalidPlan: boolean;
  email: string | null;
  loadedUsersBalances: boolean;
  amountInFiat: number;
};

type InputData = {
  amount: string;
  /**
   * this is used when bridging total cardano balance where we deduct 2 ADA
   * also used when bridging total native token balance on EVM
   */
  realAmount: string;
  inputError: ValueInputError | null;
};

export type appSliceType = {
  userTokens: WalletToken[];
  setUserTokens: (userTokens: WalletToken[]) => void;

  evmData: EVMData;
  setEvmData: (newEvmData: Partial<EVMData>) => void;

  cardanoData: CardanoData;
  setCardanoData: (newCardanoData: Partial<CardanoData>) => void;

  data: Data;
  setData: (newData: Partial<Data>) => void;

  inputData: InputData;
  setInputData: (newInputData: Partial<InputData>) => void;

  numberFormatting: { decimalSeparator: string; thousandsSeparator: string };
  setNumberFormatting: (data: { decimalSeparator: string; thousandsSeparator: string }) => void;

  selectedWalletToken: undefined | WalletToken;
  setSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => void;

  updateSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => void;

  cardanoMenuIsOpen: boolean;
  setIsCardanoMenuOpen: (cardanoMenuIsOpen: boolean) => void;

  setAvailableTokens: (availableTokens: AvailableToken[]) => void;
  availableTokens: AvailableToken[] | null;

  setNetworks: (networks: Networks) => void;
  networks: Networks | null;
};

export const createAppSlice: StateSlice<appSliceType> = (set) => ({
  userTokens: [],
  setUserTokens: (userTokens: WalletToken[]) => set({ userTokens }, false, 'setUserTokens'),

  evmData: {
    notBroadcastedExternalEVMWalletAddress: null,
    evmAddress: null,
    evmAddressRegistered: false,
  },
  setEvmData: (newEvmData: Partial<EVMData>) =>
    set((oldState) => ({ ...oldState, evmData: { ...oldState.evmData, ...newEvmData } }), false, 'setEvmData'),

  cardanoData: {
    cardanoWrongNetwork: false,
    cardanoAddress: null,
    cardanoNotBroadcastedAddress: null,
    cardanoDisconnectWallet: null,
    cardanoConnected: false,
    cardanoLoaded: false,
    cardanoConnecting: false,
    cardanoWallets: [],
    cardanoWalletRegistered: false,
    cardanoWalletName: null,
    cardanoWallet: null,
    cardanoConnectWallet: null,
  },
  setCardanoData: (newCardanoData: Partial<CardanoData>) =>
    set(
      (oldState) => ({ ...oldState, cardanoData: { ...oldState.cardanoData, ...newCardanoData } }),
      false,
      'setEvmData',
    ),

  numberFormatting: { decimalSeparator: '.', thousandsSeparator: ',' },
  setNumberFormatting: (numberFormatting: { decimalSeparator: string; thousandsSeparator: string }) =>
    set({ numberFormatting }, false, 'setNumberFormatting'),

  data: {
    initiatedPayment: false,
    acceptedPayment: false,
    approving: false,
    failed: false,
    transactionCost: '',
    notEnoughToken: false,
    completed: false,
    invalidPlan: false,
    invalidEmail: false,
    email: null,
    loadedUsersBalances: false,
    amountInFiat: 0,
  },
  setData: (newBridgeData: Partial<Data>) =>
    set((oldState) => ({ ...oldState, data: { ...oldState.data, ...newBridgeData } }), false, 'setBridgeData'),

  inputData: { amount: '', realAmount: '', inputError: null },
  setInputData: (newInputData: Partial<InputData>) =>
    set(
      (oldState) => ({
        ...oldState,
        inputData: {
          ...oldState.inputData,
          ...newInputData,
        },
      }),
      false,
      'setInputData',
    ),

  selectedWalletToken: undefined,
  setSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => {
    set(
      (oldState) => ({
        ...oldState,
        selectedWalletToken,
        transactionCost: '',
        evmData: { ...oldState.evmData, evmRequiresApproval: false },
        data: {
          ...oldState.data,
          notEnoughToken: false,
          transactionCost: '',
        },
        inputData: {
          ...oldState.inputData,
        },
      }),
      false,
      'setSelectedWalletToken',
    );
  },

  updateSelectedWalletToken: (selectedWalletToken: undefined | WalletToken) => {
    set(
      (oldState) => ({
        ...oldState,
        selectedWalletToken,
      }),
      false,
      'updateSelectedWalletToken',
    );
  },

  cardanoMenuIsOpen: false,
  setIsCardanoMenuOpen: (cardanoMenuIsOpen: boolean) => set({ cardanoMenuIsOpen }, false, 'setIsCardanoMenuOpen'),

  availableTokens: null,
  setAvailableTokens: (newAvailableTokens: AvailableToken[]) => {
    setSbAssets(newAvailableTokens);
    setSbAssetsObj(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.name] = curr;
        return prev;
      }, {}),
    );
    setSbAssetsByTokenId(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.tokenId] = curr;
        return prev;
      }, {}),
    );
    setSbAssetsByContract(
      newAvailableTokens.reduce<Record<string, AvailableToken>>((prev, curr) => {
        prev[curr.contract] = curr;
        return prev;
      }, {}),
    );
    set({ availableTokens: newAvailableTokens }, false, 'setAvailableTokens');
  },

  networks: null,

  setNetworks: (newNetworks: Networks) => {
    setNetworks(newNetworks);

    // we do this to be able to access correct network manually
    const updatedNetworkNames: INetworkNames = Object.keys(newNetworks).reduce((prev, curr) => {
      let key: keyof INetworkNames;
      switch (curr) {
        case 'preprod':
          key = 'cardano';
          break;
        case 'mumbai':
          key = 'ethereum';
          break;
        case 'fuji':
          key = 'avalanche';
          break;
        case 'kanazawa':
          key = 'meld';
          break;
        default:
          key = curr as keyof INetworkNames;
          break;
      }
      prev[key] = curr;
      return prev;
    }, {} as INetworkNames);

    setNetworkNames(updatedNetworkNames);
    setEvmNetworks(Object.values(newNetworks).filter((network) => network.chainType === 'evm'));
    set({ networks: newNetworks }, false, 'setNetworks');
  },
});
